import React from 'react';

const year = new Date().getFullYear();

export default function Footer() {
  return (
    <footer className="container mx-auto p-3 my-2 text-gray-600 text-center">
      Figgy's Backflow &copy; {year}
    </footer>
  );
}
