import React from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Logo from './Logo';
import Button from './Button';

const navLinks = [
  { label: 'Services', href: '#services' },
  { label: 'Testimonials', href: '#testimonials' },
  { label: 'Contact', href: '#contact' },
];

export default function Header() {
  return (
    <header className="sticky top-0 bg-white shadow">
      <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
        <div className="flex items-center">
          <div className="w-64 mr-3">
            <Link to="/">
              <Logo/>
            </Link>
          </div>
        </div>
        <div className="flex mt-4 sm:mt-0">
          {navLinks.map(({ label, href }, i) => {
            return (
              <AnchorLink className="px-4" href={href}>
                {label}
              </AnchorLink>
            );
          })}
        </div>
        <div className="hidden md:block">
          <Button href="#quote" className="text-sm">Get a Quote</Button>
        </div>
      </div>
    </header>
  );
}
