export default [
  {
    title: `Helped plan our backflow needs`,
    content:
      'Our condominium complex received notice from the City of San Diego that our existing backflow preventer was not in compliance. David was invaluable in helping us understand the regulations and guided us to resolution in a cost-effective and timely manner.',
    name: 'Eugene O.',
    info: 'HOA President',
    image: 'https://en.gravatar.com/userimage/2963193/899f7fde3b320b98330115b0e392e9ed.png'
  },
  // {
  //   title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
  //   content:
  //     'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
  //   name: 'John Doe',
  //   info: 'Director of Research and Data',
  //   image: 'https://placeimg.com/150/150/people'
  // },
  // {
  //   title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
  //   content:
  //     'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
  //   name: 'Jane Smith',
  //   info: 'Director of Research and Data',
  //   image: 'https://placeimg.com/150/150/people'
  // }
];
