import { DOMAIN } from './constants';

export function buildURL(pathname = '/', subdomain = 'www.') {
  return `https://${subdomain}${DOMAIN}${pathname}`;
}

export function www(pathname) {
  return buildURL(pathname);
}

export function canonical(pathname) {
  return www(pathname);
}
