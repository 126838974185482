
import React, { useRef } from 'react';

export default function NetlifyForm({ name, onSuccess, onError, children, ...props }) {
  const form = useRef();

  function onSubmit(event) {
    event.preventDefault();
    const formData = new FormData(form.current);
    const body = new URLSearchParams(formData).toString();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body,
    }).then(onSuccess, onError);
  }

  return (
    <form name={name} ref={form} onSubmit={onSubmit} {...props} data-netlify="true">
      <input type="hidden" name="form-name" value={name}/>
      {children}
    </form>
  );
}
