
import React, { useState } from 'react';
import Button from './Button';
import { ErrorMessage, SuccessMessage } from './Messages';
import { TextArea, Input } from './Forms';
import NetlifyForm from './NetlifyForm';

export default function QuoteForm() {
  const [status, setStatus] = useState();

  return (
    <div className="mx-auto md:w-8/12 lg:w-5/12 mt-8">
      <NetlifyForm name="RequestQuoteForm" className="mx-2" onSuccess={() => setStatus('success')} onError={() => setStatus('error')}>
        <Input name="fullName" label="Name" required/>
        <Input name="email" label="Email" type="email"/>
        <Input name="phone" label="Phone" type="tel"/>
        <Input name="zipCode" label="Service Zip Code"/>
        <TextArea name="description" label="How can we help you?" required/>
        {status === 'success' && <SuccessMessage/>}
        {status === 'error' && <ErrorMessage/>}
        <Button size="xl" className="mt-8" type="submit">Submit</Button>
      </NetlifyForm>
    </div>
  );
}
