import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import TestimonialCard from '../components/TestimonialCard';
import LabelText from '../components/LabelText';
import Page from '../components/Page';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import testimonials from '../data/testimonials';
import ToolsIcon from '../components/icons/ToolsIcon';
import TestingIcon from '../components/icons/TestingIcon';
import SvgCharts from '../svg/SvgCharts';
import QuoteForm from '../components/QuoteForm';
import BrokenPipeIcon from '../components/icons/BrokenPipeIcon';
import { email, mailto } from '../lib/emails';
import { PHONE } from '../lib/constants';
import ContactForm from '../components/ContactForm';

export default function IndexPage() {
  return (
    <Page>
      <section className="pt-20 md:pt-40">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2 lg:pr-2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              Backflow testing and repair experts
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              Experienced and certified backflow testers serving the greater Riverside and San Bernardino counties.
            </p>
            <p className="my-8 md:mt-12">
              <Button size="lg" href="#quote">Get a Free Quote</Button>
            </p>
          </div>
          <div className="lg:w-1/2">
            {/* TODO: move image */}
            <img className="rounded-lg" alt="Photo of Backflow Assembly" src="https://sandermechanical.com/wp-content/uploads/2016/04/Backflow-Prevention-Certification-Backflow-Preventer-Testing-1322x780.jpg"/>
          </div>
        </div>
      </section>

      <section id="services" className="my-16 lg:my-20">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">Services</h2>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <TestingIcon className="mx-auto h-12 mb-2"/>
                <p className="font-semibold text-xl">Testing &amp; Certification</p>
                <p className="mt-4">
                  We inspect and test your backflow prevention assembly to ensure it is functioning adequately and meets local certification standards.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <BrokenPipeIcon className="mx-auto h-12 mb-2"/>
                <p className="font-semibold text-xl">Assembly Repairs</p>
                <p className="mt-4">
                  We have the knowledge and expertise to repair your backflow assembly. If you have a <strong>Failed</strong> inspection, let us know and we'll get it back in working order.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <ToolsIcon className="mx-auto h-12 mb-2"/>
                <p className="font-semibold text-xl">Planning &amp; Design</p>
                <p className="mt-4">
                  Our technicians are highly trained in all backflow needs and are here to help answer your questions and plan or design your system for your project needs.
                </p>
              </Card>
            </div>
          </div>
        </div>
      </section>

      {/* <SplitSection
        id="misc"
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">Market Analysis</h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Our team of enthusiastic marketers will analyse and evaluate how your company stacks
              against the closest competitors
            </p>
          </div>
        }
        secondarySlot={<SvgCharts />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">
              Design And Plan Your Business Growth Steps
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Once the market analysis process is completed our staff will search for opportunities
              that are in reach
            </p>
          </div>
        }
        secondarySlot={<SvgCharts />}
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">
              Search For Performance Optimization
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              With all the information in place you will be presented with an action plan that your
              company needs to follow
            </p>
          </div>
        }
        secondarySlot={<SvgCharts />}
      />
      <section id="stats" className="py-20 lg:pt-32">
        <div className="container mx-auto text-center">
          <LabelText className="text-gray-600">Our customers get results</LabelText>
          <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
            <div className="w-full sm:w-1/3">
              <StatsBox primaryText="+100%" secondaryText="Stats Information" />
            </div>
            <div className="w-full sm:w-1/3">
              <StatsBox primaryText="+100%" secondaryText="Stats Information" />
            </div>
            <div className="w-full sm:w-1/3">
              <StatsBox primaryText="+100%" secondaryText="Stats Information" />
            </div>
          </div>
        </div>
      </section> */}

      <section id="quote" className="container mx-auto mt-20 mb-12 py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-semibold">Ready to service your backflow?</h3>
        <QuoteForm/>
      </section>

      <section id="testimonials" className="my-16 lg:my-20">
        <div className="container mx-auto">
          <h2 className="text-2xl font-semibold leading-tight mb-8 text-center text-gray-700">What customers are saying</h2>
          <div className="flex flex-col md:flex-row md:-mx-3">
            {testimonials.map(testimonial => (
              <div key={testimonial.name} className="flex-1 px-3">
                <TestimonialCard {...testimonial} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="contact" className="my-16 lg:my-20">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-right lg:w-1/3">
            <h2 className="text-2xl font-semibold leading-tight text-gray-700">Contact Us</h2>
            <p className="mt-8 text-xl font-light leading-relaxed">
              <strong>Email:</strong> <a href={mailto('info')}>{email('info')}</a>
              <br/>
              <strong>Phone:</strong> {PHONE}
            </p>
          </div>
          <div className="ml-20 lg:w-2/3">
            <ContactForm/>
          </div>
        </div>
      </section>
    </Page>
  );
}
