import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const sizes = {
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`
};

function NativeButton({ children, type, ...props }) {
  return (<button type={type ?? 'button'} {...props}>{children}</button>);
}

export default function Button({ children, className, size, ...props }) {
  props.className = [
    sizes[size] || sizes.default,
    className,
    'rounded',
    'whitespace-nowrap',
    'inline-block',
    'bg-primary',
    'hover:bg-primary-darker',
    'text-white',
    'hover:text-white',
  ].filter(Boolean).join(' ');

  const Button = props.href ? AnchorLink : NativeButton;

  return (<Button {...props}>{children}</Button>);
}
