import React from 'react';

function classNames(className, compact) {
  return [
    'form-control',
    'block',
    'w-full',
    compact ? 'px-3' : 'px-4',
    compact ? 'py-1' : 'py-2',
    compact ? 'text-lg' : 'text-xl',
    'font-normal',
    'text-gray-700',
    'bg-white',
    'bg-clip-padding',
    'border',
    'border-solid',
    'border-gray-300',
    'rounded',
    'transition',
    'ease-in-out',
    'm-0',
    'focus:text-gray-700',
    'focus:bg-white',
    'focus:border-blue-600',
    'focus:outline-none',
    compact ? 'mb-4' : 'mb-6',
    className
  ].filter(Boolean).join(' ')
}

export function TextArea({ name, label, className, required, compact, rows = 3 }) {
  return (
    <>
      <textarea
        name={name}
        className={classNames(className, compact)}
        placeholder={label}
        rows={rows}
        required={required}
      />
      <label for="name" className="sr-only">{label}</label>
    </>
  );
}

export function Input({ name, label, className, required, compact, type = 'text' }) {
  return (
    <>
      <input
        name={name}
        type={type}
        className={classNames(className, compact)}
        placeholder={label}
        required={required}
      />
      <label for={name} className="sr-only">{label}</label>
    </>
  );
}
