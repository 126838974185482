export default function BrokenPipeIcon({ className, color = 'current' }) {
  className = [className, `fill-${color}`].filter(Boolean).join(' ');
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"x="0px" y="0px" viewBox="0 0 512.097 512.097" className={className}>
      <g transform="translate(-1)">
        <path d="M491.763,213.382h-64c0-11.782-9.551-21.333-21.333-21.333h-64c-11.782,0-21.333,9.551-21.333,21.333v0.004h-12.544
          c-4.858,0-8.789-3.931-8.789-8.789v-12.548c11.782,0,21.333-9.551,21.333-21.333v-64c0-11.782-9.551-21.333-21.333-21.333v-64
          c0-11.782-9.551-21.333-21.333-21.333h-192c-11.782,0-21.333,9.551-21.333,21.333v64c-11.782,0-21.333,9.551-21.333,21.333v64
          c0,11.782,9.551,21.333,21.333,21.333v49.882c0,113.841,92.282,206.123,206.123,206.123h49.878
          c0.002,11.78,9.552,21.329,21.333,21.329h64c11.782,0,21.333-9.551,21.333-21.333h64c11.782,0,21.333-9.551,21.333-21.333v-192
          C513.097,222.933,503.545,213.382,491.763,213.382z M86.43,128.049h192v21.333h-192V128.049z M107.763,42.716h149.333v42.667
          H107.763V42.716z M148.624,350.031l14.991-29.982h29.482v21.333c0,11.782,9.551,21.333,21.333,21.333h42.667
          c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333h-21.333v-21.333c0-11.782-9.551-21.333-21.333-21.333
          h-64c-0.093,0-0.183,0.013-0.276,0.014c-0.438,0.006-0.872,0.033-1.307,0.066c-0.291,0.021-0.582,0.039-0.87,0.072
          c-0.36,0.042-0.715,0.103-1.071,0.163c-0.353,0.059-0.707,0.114-1.055,0.19c-0.277,0.061-0.549,0.14-0.823,0.212
          c-0.406,0.106-0.812,0.21-1.208,0.34c-0.23,0.075-0.453,0.166-0.68,0.249c-0.418,0.152-0.836,0.304-1.242,0.481
          c-0.24,0.104-0.471,0.227-0.706,0.34c-0.372,0.18-0.746,0.355-1.106,0.555c-0.294,0.162-0.575,0.346-0.861,0.523
          c-0.285,0.177-0.575,0.345-0.851,0.535c-0.34,0.232-0.664,0.488-0.991,0.741c-0.207,0.161-0.42,0.313-0.621,0.48
          c-0.34,0.283-0.662,0.589-0.985,0.895c-0.176,0.166-0.357,0.325-0.527,0.497c-0.293,0.297-0.567,0.613-0.844,0.929
          c-0.187,0.213-0.379,0.421-0.557,0.641c-0.223,0.277-0.431,0.569-0.642,0.859c-0.213,0.292-0.427,0.582-0.625,0.885
          c-0.158,0.243-0.304,0.496-0.454,0.747c-0.224,0.374-0.442,0.75-0.643,1.139c-0.043,0.083-0.094,0.158-0.136,0.241l-9.551,19.101
          c-9.016-20.276-14.035-42.724-14.035-66.346v-49.877h149.333v12.544c0,28.422,23.034,51.456,51.456,51.456h12.544v149.333
          h-49.877C222.392,405.386,178.575,383.972,148.624,350.031z M363.763,426.716v-192h21.333v192H363.763z M470.43,405.382h-42.667
          V256.049h42.667V405.382z"/>
        <path d="M81.024,369.856c-8.483-9.515-23.364-9.515-31.847,0l-30.165,33.835c-24.154,24.104-24.154,64.62,0.839,89.612
          c24.993,24.992,65.508,24.992,90.501,0c24.993-24.992,24.993-65.508,0-90.501L81.024,369.856z M80.181,463.133
          c-8.33,8.33-21.831,8.33-30.161,0s-8.33-21.831,0-30.161l15.068-16.876l14.254,15.988
          C88.511,441.303,88.511,454.803,80.181,463.133z"/>
      </g>
    </svg>

  );
}
