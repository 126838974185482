import React from 'react';
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { canonical } from '../lib/urls';
import Footer from './Footer';
import Header from './Header';

export default function Page({ title, children }) {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <meta charset="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <meta name="referrer" content="no-referrer-when-downgrade"/>
        <meta property="og:locale" content="en_US"/>
        <meta name="robots" content="index,follow"/>
        <meta name="canonical" content={canonical(location?.pathname)}/>
        <title>{title ?? `Figgy's Backflow Testing & Repair`}</title>
      </Helmet>
      <Header />
      <main className="text-gray-900">{children}</main>
      <Footer />
    </>
  );
};
