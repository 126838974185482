export default function TestingIcon({ className, color = 'current' }) {
  className = [className, `fill-${color}`].filter(Boolean).join(' ');
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 508 508" className={className}>
      <g>
        <path d="M448.8,81.3L371.6,4.1c-2.6-2.6-6.2-4.1-10-4.1H69.2c-7.8,0-14.1,6.3-14.1,14.1v479.8c0,7.8,6.3,14.1,14.1,14.1h369.6
          c7.8,0,14.1-6.3,14.1-14.1V91.3C452.9,87.5,451.4,83.9,448.8,81.3z M375.7,48.2l29,29h-29V48.2z M83.3,479.8V28.2h264.2v63
          c0,7.8,6.3,14.1,14.1,14.1h63v374.5H83.3z"/>
        <path d="M385.5,138.1H234.6c-7.8,0-14.1,6.3-14.1,14.1c0,7.8,6.3,14.1,14.1,14.1h150.9c7.8,0,14.1-6.3,14.1-14.1
          C399.6,144.4,393.3,138.1,385.5,138.1z"/>
        <path d="M385.5,271.3H234.6c-7.8,0-14.1,6.3-14.1,14.1c0,7.8,6.3,14.1,14.1,14.1h150.9c7.8,0,14.1-6.3,14.1-14.1
          C399.6,277.6,393.3,271.3,385.5,271.3z"/>
        <path d="M385.5,404.6H234.6c-7.8,0-14.1,6.3-14.1,14.1c0,7.8,6.3,14.1,14.1,14.1h150.9c7.8,0,14.1-6.3,14.1-14.1
          C399.6,410.9,393.3,404.6,385.5,404.6z"/>
        <path d="M189.1,104.8h-66.6c-7.8,0-14.1,6.3-14.1,14.1v66.6c0,7.8,6.3,14.1,14.1,14.1h66.6c7.8,0,14.1-6.3,14.1-14.1v-66.6
          C203.2,111.1,196.9,104.8,189.1,104.8z M175,171.4h-38.4V133H175V171.4z"/>
        <path d="M189.1,238.1h-66.6c-7.8,0-14.1,6.3-14.1,14.1v66.6c0,7.8,6.3,14.1,14.1,14.1h66.6c7.8,0,14.1-6.3,14.1-14.1v-66.6
          C203.2,244.4,196.9,238.1,189.1,238.1z M175,304.7h-38.4v-38.4H175V304.7z"/>
        <path d="M213.3,377.7c-5.6-5.5-14.5-5.5-20,0l-42.9,42.9l-17.8-17.8c-5.5-5.5-14.4-5.5-20,0c-5.5,5.5-5.5,14.4,0,20l27.8,27.8
          c2.8,2.8,6.4,4.1,10,4.1c3.6,0,7.2-1.4,10-4.1l52.9-52.9C218.8,392.2,218.8,383.3,213.3,377.7z"/>
      </g>
    </svg>
  )
}
