import React from 'react';
import { PHONE } from '../lib/constants';

export function Message({ color, children }) {
  return (
    <div class={`my-4 bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded`} role="alert">
      {children}
    </div>
  );
}

export function ErrorMessage({ children }) {
  return (
    // bg-red-100 bg-red-400 text-red-700
    <Message color="red">
      {children || `Something went wrong, please try again or give us a call at ${PHONE}.`}
    </Message>
  );
}

export function SuccessMessage({ children }) {
  return (
    // bg-green-100 bg-green-400 text-green-700
    <Message color="green">
      {children || 'Thank you for reaching out! We will be in touch with you soon.'}
    </Message>
  );
}
