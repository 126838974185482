
import React, { useState } from 'react';
import Button from './Button';
import { TextArea, Input } from './Forms';
import { ErrorMessage, SuccessMessage } from './Messages';
import NetlifyForm from './NetlifyForm';

export default function ContactForm() {
  const [status, setStatus] = useState();

  return (
    <div className="mx-auto w-full">
      <NetlifyForm name="ContactForm" className="mx-2" onSuccess={() => setStatus('success')} onError={() => setStatus('error')}>
        <Input name="fullName" label="Name" compact required/>
        <Input name="email" label="Email" type="email" compact/>
        <Input name="phone" label="Phone" type="tel" compact/>
        <TextArea name="description" label="Message" compact required/>
        {status === 'success' && <SuccessMessage/>}
        {status === 'error' && <ErrorMessage/>}
        <Button type="submit">Send</Button>
      </NetlifyForm>
    </div>
  );
}
