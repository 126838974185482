import React from 'react';
import Card from './Card';

export default function TestimonialCard({ title, content, image, name, info }) {
  return (
    <Card className="mb-8">
      <p className="text-xl font-semibold">{title}</p>
      <p className="mt-6">{content}</p>
      <div className="flex items-center mt-8">
        <img
          className="w-12 h-12 mr-4 rounded-full"
          src={image}
          alt={name}
        />
        <div>
          <p>{name}</p>
          {info && <p className="text-sm text-gray-600">{info}</p>}
        </div>
      </div>
    </Card>
  );
}
